
import { useAppointmentFormStore } from '~/modules/booking/stores/appointmentForm'
import { storeToRefs } from 'pinia'
import {
    defineComponent,
    onMounted
} from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    resetNotes: {
      type: Number
    },
    defaultNotes: {
      type: String
    },
  },
  setup(props) {
    const store = useAppointmentFormStore()
    const {
      notes
    } = storeToRefs(store)

  const resetNotesData = () => {
    notes.value = '';
  }

  onMounted(() => {
    if (props.defaultNotes) {
      notes.value = props.defaultNotes;
    }
  });

    return {
      notes,
      resetNotesData
    };
  },
  methods: {
    updateContent(event) {
        this.$emit('input', event.target.value);
    }
  },
  watch: {
    resetNotes(newValue, oldValue) {
        this.resetNotesData();
    },
  },
})

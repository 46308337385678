import { computed, ssrRef, useContext } from '@nuxtjs/composition-api';

export const getTimeslotsByServiceAndPharmacy = () => {
    const context = useContext();
    const result = ssrRef(null, `getTimeslotsByServiceAndPharmacy-result`);
    const loading = ssrRef(false, `getTimeslotsByServiceAndPharmacy-loading`);
    const error = ssrRef(null, `getTimeslotsByServiceAndPharmacy-error`);

    const get = async (params) => {
        const serviceId = params.serviceId;
        const pharmacyId = params.pharmacyId;
        const fromDate = params.fromDate;
        const toDate = params.toDate;

        params = {
            serviceId: serviceId,
            pharmacyId: pharmacyId,
            fromDate: fromDate,
            toDate: toDate
        }

        try {
            loading.value = true;
            result.value = await context.$vsf.$magentoRestApi.api.getTimeslotsByServiceAndPharmacy(params);
            error.value = null;
        } catch (exception) {
            error.value = exception;
            console.log(`getTimeslotsByServiceAndPharmacy error`, exception);
        } finally {
            loading.value = false;
        }
    };

    return {
        get,
        result: computed(() => result.value),
        loading: computed(() => loading.value),
        error: computed(() => error.value)
    }
};

import { defineStore } from 'pinia';
import {
    ref,
} from '@nuxtjs/composition-api';

export const useAppointmentFormStore = defineStore('appointmentForm', () => {
    const serviceId = ref(null);
    const serviceName = ref(null);
    const pharmaciesBySelectedService = ref(null);
    const selectedPharmacy = ref(null);
    const notes = ref('');
    const appointmentStart = ref('');
    const appointmentStartTime = ref('')
    const appointmentStartDate = ref('')
    const appointmentFormattedStartDate = ref('')
    const appointmentEnd = ref('');
    const appointmentEndTime = ref('')
    const appointmentEndDate = ref('')
    const appointmentFormattedEndDate = ref('')
    const resetService = ref(0)
    const resetNotes = ref(0)
    const availablePharmacies = ref(null)
    const telephone = ref(null)
    const fiscalCode = ref(null)
    const isHolter = ref(false)
    const returnAppointmentStart = ref('');
    const returnAppointmentStartTime = ref('')
    const returnAppointmentStartDate = ref('')
    const returnAppointmentFormattedStartDate = ref('')
    const returnAppointmentEnd = ref('');
    const returnAppointmentEndTime = ref('')
    const returnAppointmentEndDate = ref('')
    const returnAppointmentFormattedEndDate = ref('')

    return {
        serviceId,
        serviceName,
        pharmaciesBySelectedService,
        selectedPharmacy,
        notes,
        appointmentStart,
        appointmentStartTime,
        appointmentStartDate,
        appointmentFormattedStartDate,
        appointmentEnd,
        appointmentEndTime,
        appointmentEndDate,
        appointmentFormattedEndDate,
        resetService,
        resetNotes,
        availablePharmacies,
        telephone,
        fiscalCode,
        isHolter,
        returnAppointmentStart,
        returnAppointmentStartTime,
        returnAppointmentStartDate,
        returnAppointmentFormattedStartDate,
        returnAppointmentEnd,
        returnAppointmentEndTime,
        returnAppointmentEndDate,
        returnAppointmentFormattedEndDate
    }
})


import { useDatepickerStore } from '~/modules/booking/stores/datepicker'
import { storeToRefs } from 'pinia'

import {
    defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    resetSelectedTimeslot: {
      type: Number
    }
  },
  setup(props, context) {
    const store = useDatepickerStore()
    const {
      selectedTimeslot,
      availableTimeslotsForSelectedDay,
    } = storeToRefs(store)

      const emitChosenDateAndTimeslot = (selectedTimeslotObject, index) => {
        context.emit('date-timeslot-selected', selectedTimeslotObject);
        selectedTimeslot.value = index;
      }

      const resetTimeslotsData = () => {
        selectedTimeslot.value = null;
     }

      return {
        emitChosenDateAndTimeslot,
        selectedTimeslot,
        resetTimeslotsData,
        availableTimeslotsForSelectedDay
      };
  },
  watch: {
    resetSelectedTimeslot(newValue, oldValue) {
      this.resetTimeslotsData();
    },
  },
})

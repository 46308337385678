import { defineStore } from 'pinia';
import {
    ref,
} from '@nuxtjs/composition-api';

export const useDatepickerStore = defineStore('datepicker', () => {
    // Datepicker component
    const availableDates = ref([])
    const fromDate = ref('')
    const toDate = ref('')
    const renderDatePickerAgainKey = ref(0)
    const currentDate = ref(new Date())
    const resetSelectedTimeslot = ref(0)
    const chosenDate = ref(null);
    const availableTimeslotsForSelectedDay = ref(null)

    // timeslots component
    const selectedTimeslot = ref(null)
    return {
        availableDates,
        availableTimeslotsForSelectedDay,
        fromDate,
        toDate,
        renderDatePickerAgainKey,
        currentDate,
        resetSelectedTimeslot,
        chosenDate,
        selectedTimeslot
    }
})
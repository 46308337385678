const dateObjectToString = (dateObject, onlyDate = true, onlyMinutes = false, formatDateString = false) => {
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');

    let dateString = `${year}-${month}-${day}`;
    const hourMinutesString = `${hours}:${minutes}`;

    if (formatDateString) {
        dateString = `${day}/${month}/${year}`;
    }

    if (onlyDate) {
        return dateString
    }

    if (onlyMinutes) {
        return `${hours}:${minutes}`;
    }

    return `${dateString} ${hourMinutesString}`;
}

const addDays = (date, days) => {
    return addOrSubtractDays(date, days);
}

const subtractDays = (date, days) => {
    return addOrSubtractDays(date, days, false);
}

const addOrSubtractDays = (date, days, add = true) => {
    let dateClone = new Date(date)
    if (add) {
        dateClone.setDate(dateClone.getDate() + days);
    } else {
        dateClone.setDate(dateClone.getDate() - days);
    }
    return new Date(dateClone)
}

const addMinutesToDateObject = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
}

const exportsObj = {
    dateObjectToString,
    addDays,
    subtractDays,
    addMinutesToDateObject
};

export default exportsObj;


import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/it';
import {getTimeslotsByServiceAndPharmacy} from '~/modules/booking/composables/timeslots';
import TimeslotsComponent from '~/modules/booking/components/appointment/forms/organisms/datepicker/Timeslots';
import dateHelper from "~/helpers/dateHelper";
import { useDatepickerStore } from '~/modules/booking/stores/datepicker'
import { storeToRefs } from 'pinia'

import {
  defineComponent,
  ref,
  onMounted
} from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    serviceId: {
      type: Number
    },
    pharmacyId: {
      type: Number
    },
    defaultSelectedDate: {
      type: String
    },
    fetchTimeslotsOnPageLoad: {
      type: Boolean
    }
  },
  components: {
    DatePicker,
    TimeslotsComponent
  },
  setup(props, context) {
    const {
      result: getTimeslotsByServiceAndPharmacyResult,
      error: getTimeslotsByServiceAndPharmacyError,
      get: getTimeslotsByServiceAndPharmacyData,
      loadingGetTimeslotsByServiceAndPharmacy
    } = getTimeslotsByServiceAndPharmacy();

    const store = useDatepickerStore()
    const {
      availableDates,
      availableTimeslotsForSelectedDay,
      fromDate,
      toDate,
      renderDatePickerAgainKey,
      currentDate,
      resetSelectedTimeslot,
      chosenDate
    } = storeToRefs(store)

    const fetchTimeslots = async (serviceId, pharmacyId, keepChosenDate = false) => {
      availableDates.value = [];
      availableTimeslotsForSelectedDay.value = [];
      resetSelectedTimeslot.value +=1;

      if (!keepChosenDate) {
        chosenDate.value = null;
      }

      if (pharmacyId === undefined || serviceId === undefined) {
        alert('first select service and/or pharmacy!') // TODO vervangen
        return
      }

      await getTimeslotsByServiceAndPharmacyData(
        {
          serviceId: serviceId,
          pharmacyId: pharmacyId,
          fromDate: fromDate.value,
          toDate: toDate.value
        }
      );

      // Error value is null and value of createAppointmentsResult === true, the appointment is created
      if (!getTimeslotsByServiceAndPharmacyError.value && getTimeslotsByServiceAndPharmacyResult.value) {
        formatTimeslots()
        // Update key for rendering the datepicker again based on new fetched time slots. Otherwise, the dates
        // in the datepicker will not update based on the new fetched timeslots by updated pharmacyId
        rerenderDatePicker()

        // Keep chosendate and timeslots. this is needed when for example get available dates and timeslots on
        // page load in edit appointment page.
        if (keepChosenDate && chosenDate.value) {
          getTimeSlotsByDate(new Date(chosenDate.value))
        }
        return
      }

      alert('Getting timeslots failed!') // TODO: vervangen
    }

    const formatTimeslots = () => {
      for (const item of getTimeslotsByServiceAndPharmacyResult.value) {
        // Extracting the current year, month, and day
        const dateString = item.date;

        for(const dateData of item.timeslots) {
          if (!availableDates.value[dateString]) {
            availableDates.value[dateString] = [];
          }

          const timeslotDate = {
            fromDateTime: dateData.date_time,
            fromTime: dateData.time,
            fromTime24h: dateData.time_24h,
            timelineInterval: item.timelineInterval,
            dayName: item.day_name,
            holterInfo: item.holter_info
          }

          availableDates.value[dateString].push(timeslotDate);
        }
      }
    }

    /**
     * Get the available timeslots by given date
     * @param date
     */
    const getTimeSlotsByDate = (date) => {
      resetSelectedTimeslot.value +=1;

      if (Object.keys(availableDates.value).length > 0) {
        const dateString = dateHelper.dateObjectToString(date, true);
        if (date && availableDates.value[dateString]) {
          availableTimeslotsForSelectedDay.value = availableDates.value[dateString]
        }
      }
    }

    const pickedDate = (date) => {
      emitChosenDateAndTimeslot(null)
      getTimeSlotsByDate(date)
    }

    const emitChosenDateAndTimeslot = (selectedTimeslotData) => {
      context.emit('date-timeslot-selected', selectedTimeslotData);
    }

    const rerenderDatePicker = () => {
      renderDatePickerAgainKey.value +=1;
    }

    const renderTimeSlotsAfterCalenderChange = (date, oldDate, type) => {
      // max toDate: 13
      // max from:  22
      const fromDateObj = dateHelper.subtractDays(date, 10);
      const toDateObj = dateHelper.addDays(date, 45);

      fromDate.value = dateHelper.dateObjectToString(fromDateObj, true);
      toDate.value = dateHelper.dateObjectToString(toDateObj, true);

      currentDate.value = date;
      // This is a vmodel of datepicker. This needs to be updated, otherwise the value will be different
      // from the currentDate when rerender the component in the fetchTimeslots function
      chosenDate.value = null;

      fetchTimeslots(props.serviceId, props.pharmacyId)
    }

    /**
     * Return false for show available date
     * @param date
     * @returns {boolean}
     */
    const setAvailableDates = (date) => {
      if (Object.keys(availableDates.value).length > 0) {
        const dateString = dateHelper.dateObjectToString(date, true);
        if (date && availableDates.value[dateString]) {
          return false;
        }
      }

      // Return true to disable date by default
      return true;
    };

    const setFromDate = (date) => {
      fromDate.value = dateHelper.dateObjectToString(date, true);
    }

    const setToDate = (date) => {
      toDate.value = dateHelper.dateObjectToString(date, true);
    }

    const setFromAndToDate = (date) => {
      if (!date) {
        date = new Date() // set date to today
      }
      let firstDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);

      const fromDateObj = dateHelper.subtractDays(firstDayOfCurrentMonth, 10);
      const toDateObj = dateHelper.addDays(firstDayOfCurrentMonth, 45);

      setFromDate(fromDateObj)
      setToDate(toDateObj)
    }

    const resetDatePickerData = () => {
      availableDates.value = []
      availableTimeslotsForSelectedDay.value = {};
      resetSelectedTimeslot.value +=1;
      currentDate.value = new Date()
      chosenDate.value = null;

      setFromAndToDate(null);
      rerenderDatePicker()
    }

    const setDefaultFromAndToDate = () => {
      let date = new Date();
      if (props.defaultSelectedDate !== undefined) {
        date = new Date(props.defaultSelectedDate);
      }

      currentDate.value = date;
      chosenDate.value = props.defaultSelectedDate;
      setFromAndToDate(date)
    }

    onMounted(() => {
      setDefaultFromAndToDate();

      if (props.fetchTimeslotsOnPageLoad) {
        // Set keep chosen date on true for the edit appointment page for example.
        fetchTimeslots(props.serviceId, props.pharmacyId, true)
      }
    });

    return {
      chosenDate,
      availableDates,
      setAvailableDates,
      fetchTimeslots,
      renderDatePickerAgainKey,
      getTimeslotsByServiceAndPharmacyData,
      getTimeSlotsByDate,
      emitChosenDateAndTimeslot,
      renderTimeSlotsAfterCalenderChange,
      fromDate,
      toDate,
      currentDate,
      resetDatePickerData,
      resetSelectedTimeslot,
      rerenderDatePicker,
      pickedDate
    };
  },
  watch: {
    /**
     * @param newValue
     * @param oldValue
     */
    serviceId(newValue, oldValue) {
      if (newValue === oldValue) return

      this.resetDatePickerData()
    },
    /**
     * We only need to watch the parmacyId and not the serviceId because the pharmacyId depends on the service id and
     * is the latest param to select for getting timeslots
     * @param newValue
     * @param oldValue
     */
    pharmacyId(newValue, oldValue) {
      if (newValue === oldValue) return

      // When appointment is created, reset all data and rerender datepicker
      if (!newValue) {
        this.resetDatePickerData()
        return
      }
      this.fetchTimeslots(this.serviceId, newValue);
    },
  },

})
